
// SignUp, BikeVerifiedFromJpj, BikeVerifiedByOpsUser, BikeRejectedByOpsUser, BikeApplicableForManual,
// BonusPointsAwarded, VoucherAwarded, VoucherAwardedManually, PointsAwardedForPurchaseByMerchant, EventCheckIn,
// PointsAwardedOnCheckIn, PointsAwardedForWoocommerceOrder, PointsAwardedForGOSOrder, PointsAwardedManually,
// PointsDeductedManually,
// BikeAssigned, RemovedAssignedUser, PointsExpired, VoucherExpired, EServiceCouponExpired, VoucherRedeemed, EServiceCouponRedeemed,
// EmailUpdated, MobileNumberUpdated, PasswordUpdated, ServiceRequestRaised, UploadedMembershipCard, MembershipUpgraded,
// MembershipDowngraded, MembershipExpired, MembershipReactivated, PointsDeductionRequestByMerchant




export const defaultMessages = {
    "Sign Up": {
        "action": "Sign Up",
        "placeHolders": ["<Member name>", "<Membership Number>", "<Membership card number>", "<Membership Tier>"],
        "subjectApplicableOnlyForEmail": "Welcome to Genblu portal",
        "message": "Hi <Member name>,\n\nCongratulations! you have successfully registered on the Genblu portal. Explore the Genblu application to earn points, rewards and take part in the exclusive Genblu campaigns.\n\nBelow are your membership details.\nMembership number: <Membership Number>\nMembership card number: <Membership card number>\nTier: <Membership Tier>\n\nTeam Genblu\n"
    },
    "Bike Verified From Jpj": {
        "action": "Bike Verified From Jpj",
        "placeHolders": ["<Member name>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "Bike details verified - <plate number>",
        "message": "Hi <Member name>,\nCongratulations! Bike with plate number <plate number> is successfully verified and registered on the Genblu portal. You can see the details of the bike under the Bike details section on the application.\n\nTeam Genblu"
    },
    'Bike Verified By Ops User': {
        "action": "Bike Verified By OpsUser",
        "placeHolders": ["<Member name>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "Bike details verified - <plate number>",
        "message": "Hi <Member name>,\nCongratulations! Bike with plate number <plate number> is successfully verified and registered on the Genblu portal. You can see the details of the bike under the Bike details section on the application.\n\nTeam Genblu"
    },
    'Bike Rejected By Ops User': {
        "action": "Bike Rejected By OpsUser",
        "placeHolders": ["<Member name>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "Bike details rejected - <plate number>",
        "message": "Hi <Member name>,\nWe are sorry to inform you that Bike with  <plate number> is rejected by our team after validating the details provided by you. For more information kindly visit the bike details section on the Genblu application.\n\nTeam Genblu"
    },
    "Bike Applicable For Manual": {
        "action": "Bike Applicable For Manual",
        "placeHolders": ["<Member name>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "Bike details verified - <plate number>",
        "message": "Hi <Member name>,\nCongratulations! Bike with plate number <plate number> is successfully verified and registered on the Genblu portal. You can see the details of the bike under the Bike details section on the application.\n\nTeam Genblu"
    },
    "Bonus Points Awarded": {
        "action": "Bonus Points Awarded",
        "placeHolders": ["<Member name>", "<bonus points count>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "Bonus points",
        "message": "Hi <Member name>,\nHurray! You have earned <bonus points count> bonus points on registering your bike <plate number>. Keep on adding bikes on the Genblu application to earn more bonus points.\n\nYou can redeem the earned points by visiting the Redeem section on your Genblu application\n\nTeam Genblu"
    },
    // "Event Check In":{
    //     "action": "Event Check In",
    //     "placeHolders" : ["<Member name>","<campaign title>" , "<check-in timings>" ," <voucher date>"],
    //     "subjectApplicableOnlyForEmail": "Successful check-in to the <Campaign Tiltle>",
    //     "message": "Hi <Member name>,\n\nYou have successfully check-in to <campaign title> on <check-in timings>. Have a pleasant time and enjoy. \n\nTeam Genblu"
    // },
    "Points Awarded On CheckIn": {
        "action": "Points Awarded On CheckIn",
        "placeHolders": ["<Member name>", "<campaign title>", "<check-in timings>"],
        "subjectApplicableOnlyForEmail": "Points earned on your check-in to <campaign title>",
        "message": "Hi <Member name>,\n\nHurray! You have earned <points awarded count> points on your check-in to <campaign title> on <check-in timings>.\n\nKeep participating in the Genblu campaigns to earn more and more points.\n\nYou can redeem the earned points by visiting the Redeem section on your Genblu application\n\n\nTeam Genblu"
    },
    "Points Redeemed For Woocommerce Order": {
        "action": "Points Redeemed For Woocommerce Order",
        "placeHolders": ["<Member name>", "<Product title>", "<order punch timing>", "<points deducted count>"],
        "subjectApplicableOnlyForEmail": "Points redeemed",
        "message": "Hi <Member name>,\n\nCongratulations! Your order consisting <Product title>  is successfully placed on <order punch timing> and <points deducted count> points have been redeemed against your purchase on WooCommerce application.\n\nThanks for shopping with us.\n\nTeam Genblu"
    },
    "Points Awarded For GOS Order": {
        "action": "Points Awarded For GOS Order",
        "placeHolders": ["<Member name>", "<points awarded count>", "<Amount spent>", "<Purchase timings>"],
        "subjectApplicableOnlyForEmail": "Points earned on your purchase",
        "message": "Hi <Member name>,\n\nHurray! You have earned <points awarded count> points on your purchase of RM<Amount spent> from Genblu e-commerce platform on <Purchase timings>. \n\nKeep purchasing to earn more and more points.\n\nYou can redeem the earned points by visiting the Redeem section on your Genblu application\n\n\nTeam Genblu"
    },
    "Points Awarded Manually": {
        "action": "Points Awarded Manually",
        "placeHolders": ["<Member name>", "<points awarded count>",],
        "subjectApplicableOnlyForEmail": "Points awarded by Genblu Team",
        "message": "Hi <Member name>,\nHurray! You have been awarded <points awarded count> points by Genblu Team. Keep exploring the Genblu application to earn more rewards.\n\nYou can redeem the awarded points by visiting the Redeem section on your Genblu application\n\nTeam Genblu"

    },
    "Points Deducted Manually": {
        "action": "Points Deducted Manually",
        "placeHolders": ["<Member name>", "<Points Deduct count - Manually> "],
        "subjectApplicableOnlyForEmail": "Points deducted by Genblu Team",
        "message": "Hi <Member name>,\n\n<Points Deduct count - Manually> points have been deducted by Genblu Team.\n\nTeam Genblu"
    },
    "Bike Assigned": {
        "action": "Bike Assigned",
        "placeHolders": ["<Member name>", "<plate number>", "<assignee name>"],
        "subjectApplicableOnlyForEmail": "Bike assigned successfully",
        "message": "Hi <Member name>,\n\nCongratulations! You have successfully assigned your bike <plate number> to <assignee name>.\n\nKindly note that a bike can be assigned to one member only and a member can be assigned to one bike only.\n\nTeam Genblu"
    },
    "Removed Assigned User": {
        "action": "Removed Assigned User",
        "placeHolders": ["<Member name>", "<assignee name>", "<plate number>"],
        "subjectApplicableOnlyForEmail": "User removed successfully",
        "message": "Hi <Member name>,\n\nYou have successfully removed <assignee name> as an assignee to your bike <plate number>.\n\n<plate Number> can now be assigned to other member\n\nTeam Genblu"
    },
    "Points Expired": {
        "action": "Points Expired",
        "placeHolders": ["<Member name>", "<Points expired count>", "<Points expired date>"],
        "subjectApplicableOnlyForEmail": "Points expired",
        "message": "Hi <Member name>,\n\n<Points expired count> points have been expired on <Points expired date>. \n\nHurry up and redeem your remaining points before they also get expired.\n\nTeam Genblu"
    },
    "Voucher Expired": {
        "action": "Voucher Expired",
        "placeHolders": ["<Member name>", "<Voucher expiry date>", "<Voucher code>"],
        "subjectApplicableOnlyForEmail": "Voucher expired",
        "message": "Hi <Member name>,\n\n<Voucher code> has been expired on <Voucher expiry date>.\n\nHurry up and use your remaining vouchers before they also get expired.\n\nTeam Genblu"
    },
    "EService Coupon Expired": {
        "action": "EService Coupon Expired",
        "placeHolders": ["<Member name>", "<E-service code>", "<E-Service coupon expiry date>"],
        "subjectApplicableOnlyForEmail": "E-service coupon expired",
        "message": "Hi <Member name>,\n\nYour e-service coupon <E-service code> has been expired on <E-Service coupon expiry date>.\n\nHurry up and use your remaining E-service coupons before they also get expired.\n\nTeam Genblu"
    },
    "Voucher Redeemed": {
        "action": "Voucher Redeemed",
        "placeHolders": ["<Member name>", "<Voucher code>", "<Amount spent>", " <Business entity name>", " <Purchase timings>"],
        "subjectApplicableOnlyForEmail": "Voucher redeemed successfully",
        "message": "Hi <Member name>,\n\nCongratulations! You have successfully redeemed your voucher <Voucher code> on purchase of RM<Amount spent> from <Business entity name> on <Purchase timings>\n\nTeam Genblu"
    },
    "E Service Coupon Redeemed": {
        "action": "E Service Coupon Redeemed",
        "placeHolders": ["<Member name>", "<E-service code>", "<E-Service voucher redemption date>"],
        "subjectApplicableOnlyForEmail": "E-service coupon redeemed successfully",
        "message": "Hi <Member name>,\n\nCongratulations! You have successfully redeemed your e-service coupon <E-service code> on <E-Service voucher redemption date> \n\nTeam Genblu"
    },
    "Email Updated": {
        "action": "Email Updated",
        "subjectApplicableOnlyForEmail": "Email address updated successfully",
        "placeHolders": ["<Member name>", "<updated email id>"],
        "message": "Hi <Member name>,\n\nHurray! Your email address <updated email id> has been updated successfully and a verification link is also sent to the updated email address. Kindly click on the verification link to verify the updated email address. \n\nTeam Genblu"
    },
    "Mobile Number Updated": {
        "action": "Mobile Number Updated",
        "subjectApplicableOnlyForEmail": "Mobile number updated successfully",
        "placeHolders": ["<Member name>", "<updated mobile number>"],
        "message": "Hi <Member name>,\n\nHurray! Your mobile number <updated mobile number> has been updated successfully.\n\nYou can now login using the updated mobile number on your Genblu application.\n\nTeam Genblu"
    },
    "Password Updated": {
        "action": "Password Updated",
        "placeHolders": ["<Member name>"],
        "subjectApplicableOnlyForEmail": "Password updated successfully",
        "message": "Hi <Member name>,\n\nHurray! Your password has been updated successfully.\n\nYou can now login using the updated password on your Genblu application.\n\nTeam Genblu"
    },
    "Service Request Raised": {
        "action": "Service Request Raised",
        "subjectApplicableOnlyForEmail": "Service request raised successfully",
        "placeHolders": ["<Member name>", "<request raising date>", "<Unique service request number>"],
        "message": "Hi <Member name>,\n\nWe have successfully received the request being raised by you on <request raising date> and your ticket number is <Unique service request number>. Kindly visit the Genblu application to see the latest update on your request.\n\n\nTeam Genblu"
    },
    "Uploaded Membership Card": {
        "action": "Uploaded Membership Card",
        "subjectApplicableOnlyForEmail": "Membershhip card uploaded successfully",
        "placeHolders": ["<Member name>", "<uploaded membership card number>"],
        "message": "Hi <Member name>,\n\nCongratulations! You have successfully added the membership card <uploaded membership card number> on the Genblu application.\n\nYou can also see the details of your membership cards under the manage membership section on the Genblu application.\n\nTeam Genblu"
    },
    "Membership Upgraded": {
        "action": "Membership Upgraded",
        "subjectApplicableOnlyForEmail": "Congratulations! your tier is upgraded",
        "placeHolders": ["<Member name>", "<Upgraded tier>", "<Previous membership tier>"],
        "message": "Hi <Member name>,\n\nHurray! Your tier has been upgraded to <Upgraded tier> from <Previous membership tier>. \n\nThanks for being associated with us and keep exploring the Genblu application to earn more rewards\n\nTeam Genblu"
    },
    "Membership Downgraded": {
        "action": "Membership Downgraded",
        "subjectApplicableOnlyForEmail": "Your tier is downgraded",
        "placeHolders": ["<Member name>", "<Downgraded tier>", "<Previous membership tier>"],
        "message": "Hi <Member name>,\n\nYour tier has been downgraded to <Downgraded tier> from <Previous membership tier>. \n\nBut don't worry we have your back and you will always be our star member.\n\nTeam Genblu"
    },
    "Membership Expired": {
        "action": "Membership Expired",
        "subjectApplicableOnlyForEmail": "Genblu subscription expired",
        "placeHolders": ["<Member name>", "<Membership expiry date>"],
        "message": "Hi <Member name>,\n\nWe are sorry to inform you that your Genblu subscription has been expired on <Membership expiry date> and as a result, you will be no longer to enjoy the benefits of being a Genblu member.\n\nBut don't worry all you have to do is to reactivate your membership plan by visiting the Genblu application.\n\nTeam Genblu"
    },
    "Membership Reactivated": {
        "action": "Membership Reactivated",
        "subjectApplicableOnlyForEmail": "Membership plan reactivated successfully",
        "placeHolders": ["<Member name>", "<Membership validity date>"],
        "message": "Hi <Member name>,\n\nCongratulations! You have successfully reactivated your membership plan. We appreciate and are thankful to you for being associated with us.\n\nYour updated membership validity date is <Membership validity date>, till then keep exploring the Genblu application and enjoy exclusive benefits made for you.\n\nTeam Genblu"

    },
    "Voucher Awarded": {
        "action": "Voucher Awarded",
        "subjectApplicableOnlyForEmail": "Vouchers Awarded",
        "placeHolders": ["<Member name>", "<voucher count>", "<voucher prefix>", "<voucher date>"],
        "message": "Hi <Member name>,\nHurray! You have been awarded <voucher count> <voucher prefix> on <voucher date>. To see the details of the awarded vouchers kindly visit the Voucher section on your Genblu application.\n\nYou can redeem these vouchers on your event and regular purchases. \n\nTeam Genblu"
    },
    "Voucher Awarded Manually": {
        "action": "Voucher Awarded Manually",
        "placeHolders": ["<Member name>", "<voucher count>", "<voucher prefix>", "<voucher date>"],
        "subjectApplicableOnlyForEmail": "Vouchers awarded by Genblu Team",
        "message": "Hi <Member name>,\nHurray! You have been awarded <voucher count> <voucher prefix> on <voucher date> by the Genblu Team. To see the details of the awarded vouchers kindly visit the Voucher section on your Genblu application.\n\nYou can redeem these vouchers on your event and regular purchases. \n\nTeam Genblu"

    },
    "Points Awarded For Purchase By Merchant": {
        "action": "Points Awarded For Purchase By Merchant",
        "placeHolders": ["<Member name>", "<points awarded count>", "<Amount spent>", "<Business entity name>", "<Purchase timings>"],
        "subjectApplicableOnlyForEmail": "Points earned on your purchase",
        "message": "Hi <Member name>,\n\nHurray! You have earned <points awarded count> points on your purchase of RM<Amount spent> from <Business entity name> on <Purchase timings>. \n\nKeep purchasing to earn more and more points.\n\nYou can redeem the earned points by visiting the Redeem section on your Genblu application\n\n\nTeam Genblu"
    },
    "Points Deduction Request By Merchant": {
        "action": "Points Deduction Request By Merchant",
        "placeHolders": ["<Member name>", "<points deducted count>", "<Business entity name>"],
        "subjectApplicableOnlyForEmail": "Points deduct For POS Discount",
        "message": "Hi <Member name>,\n\n<Business entity name> has initiated <points deducted count> points deduction for your redemption of cash discount. Please click ‘Approve’ to provide consent.\n\n\nTeam Genblu"
    },
    "Points Redeemed For POS Discount": {
        "action": "Points Redeemed For POS Discount",
        "placeHolders": ["<Member name>", "<points deducted count>", "<Business entity name>"],
        "subjectApplicableOnlyForEmail": "Points Redeemed For POS Discount",
        "message": "Hi <Member name>,\n\nCongratulations! Your <points deducted count> points have been redeemed for cash discount on the POS application of <Business entity name>.\n\nThanks for shopping with us.\n\nTeam Genblu"
    },
    "Points Awarded By Merchant": {
        "action": "Points Awarded By Merchant",
        "placeHolders": ["<Member name>", "<points awarded count>", "<Business entity name>"],
        "subjectApplicableOnlyForEmail": "Points Awarded By Merchant",
        "message": "Hi <Member name>,\n\nHurray! You have earned <points awarded count> points from <Business entity name>. \n\nKeep purchasing to earn more and more points.\n\n\nTeam Genblu"
    },
    "Points redeemed on purchase of Digital Voucher": {
        "action": "Points redeemed on purchase of Digital Voucher",
        "placeHolders": ["<Member name>", "<points deducted count>", "<Business entity name>", "<voucher prefix>", "<voucher date>"],
        "subjectApplicableOnlyForEmail": "Points redeemed on purchase of Digital Voucher",
        "message": "Hi <Member name>,\n\nCongratulations! Your <points deducted count> points have been redeemed on purchase of Digital Voucher for availing discount.\n\nThanks for shopping with us.\n\nTeam Genblu"
    },
    "Points Awarded By Yamaha On Signup via Referral": {
        "action": "Points Awarded By Yamaha On Referral",
        "placeHolders": ["<Member name>", "<points awarded count>", "<Business entity name>"],
        "subjectApplicableOnlyForEmail": "Points Awarded By Yamaha On Referral",
        "message": "Hi <Member name>,\n\nHurray! You have earned <points awarded count> points from Yamaha team on signing up via referral.\n\n\nTeam Genblu"
    },
    "Points Awarded By Yamaha For Referring a friend": {
        "action": "Points Awarded By Yamaha On Referral",
        "placeHolders": ["<Member name>", "<points awarded count>", "<Business entity name>"],
        "subjectApplicableOnlyForEmail": "Points Awarded By Yamaha On Referral",
        "message": "Hi <Business entity name>,\n\nHurray! You have earned <points awarded count> points from Yamaha for referring a friend.\n\n\nTeam Genblu"
    }
}