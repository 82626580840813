import { Button, Chip, makeStyles, TextField } from '@material-ui/core';
import React, { useState, useEffect, Fragment } from 'react';
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import pointsApiService from './APIService';
import HWMessage from 'HWLibrary/HWMessage';
import { useCallback } from 'react';
import BlockUi from 'react-block-ui';
import { DropDown } from 'views/Merchant/Merchant';
import ConfirmationDialog from 'views/Utils/ConfirmationDialog';
import { DropzoneDialog } from 'material-ui-dropzone';
import settingsApiService from './APIService';
import HWLocalStorage from 'HWLibrary/HWLocalStorage';
import { convertRowsPropToState } from '@material-ui/data-grid';
import { defaultMessages } from './InAppMessages';
import Edit from '@material-ui/icons/Edit';
import StatusDialog from 'views/Utils/StatusDialog';
import Draggable from 'react-draggable';
import AutocompleteTextArea2 from 'views/Utils/TextAreaAutoComplete2';

const useStyles = makeStyles(theme => ({
    ...styles,
    saveButton: { color: 'floralwhite', backgroundColor: '#138DE8', margin: 2.5 },
    cancelButton: { color: 'floralwhite', backgroundColor: '#FF9017', margin: 2.5 },
    card: { width: 1000 }
}));

export default function RuleForm(props) {

    var settings = []
    var obj = JSON.parse(HWLocalStorage.load("Permissions"))
    console.log(obj);
    settings = [...obj["Settings"]];

    const classes = useStyles();
    const [openSingle, setOpenSingle] = React.useState(false);

    const [currentRule, setCurrentRule] = useState({});
    const [ruleLoading, setRuleLoading] = useState(false);
    const [productCategoryList, setProductCategoryList] = useState([]);
    const [tierList, setTierList] = useState([]);
    const { createMode, ruleId } = props;
    const [hasError, setHasError] = useState({});
    const [errorMessage, setErrorMessage] = useState({});
    const [saveOpen, setSaveOpen] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [placeHolders, setPlaceHolders] = useState([]);

    const types = ["Sign Up", "Bike Verified From Jpj", "Bike Verified By Ops User", "Bike Rejected By Ops User", "Bike Applicable For Manual",
        "Bonus Points Awarded", "Voucher Awarded", "Voucher Awarded Manually", "Points Awarded For Purchase By Merchant",
        "Points Awarded On CheckIn", "Points Redeemed For Woocommerce Order", "Points Awarded For GOS Order", "Points Awarded Manually", "Points Deducted Manually",
        "Bike Assigned", "Removed Assigned User", "Points Expired", "Voucher Expired", "EService Coupon Expired", "Voucher Redeemed", "E Service Coupon Redeemed",
        "Email Updated", "Mobile Number Updated", "Password Updated", "Service Request Raised", "Uploaded Membership Card", "Membership Upgraded",
        "Points Deduction Request By Merchant", "Points Redeemed For POS Discount", "Points Awarded By Merchant",
        "Membership Downgraded", "Membership Expired", "Membership Reactivated",
        "Points redeemed on purchase of Digital Voucher", "Points Awarded By Yamaha On Signup via Referral", "Points Awarded By Yamaha For Referring a friend"];







    const checkError = field => {
        if (hasError[field] && hasError[field] === true) return true;
        else return false;
    };
    const fetchErrorMessage = field => {
        var errorStatus = hasError[field];
        if (errorStatus === false) { return null; }
        else return errorMessage[field];
    }




    const isDisabled = false;


    useEffect(() => {
        if (props.createMode == false) {
            setCurrentRule(props.ruleId);
        }
    }, [props.ruleId])


    const updateRule = useCallback(rule => {
        if (rule !== null) {
            let successStatus = null;
            rule.action = rule.action.replace(/\s+/g, "");
            rule.actionType = "InApp";
            setRuleLoading(true);
            settingsApiService.updateAppNotification(rule)
                .then(response => {
                    if (!response || !response.data) { HWMessage.show("Error while fetching response", "error"); }
                    setCurrentRule(response.data);
                    successStatus = true;
                    setOpen3(true);
                    HWMessage.show("Request Successful", "success");
                }).catch(error => {
                    HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    successStatus = false;
                })
                .finally(() => {
                    setRuleLoading(false);
                    if (successStatus === true) {
                        props.setDirectActionOpen(false);
                        props.setRefreshState(prev => !prev)
                    }
                });
        }
    });
    const createRule = useCallback(rule => {
        if (rule !== null) {
            let successStatus = null;
            setRuleLoading(true);
            rule.action = rule.action.replace(/\s+/g, "");
            rule.actionType = "InApp";
            settingsApiService.createAppNotification(rule)
                .then(response => {
                    if (!response || !response.data) { HWMessage.show("Error while fetching response", "error"); }
                    setCurrentRule(response.data);
                    props.setRefreshState(prev => !prev);
                    successStatus = true;
                    setOpen2(true);
                    HWMessage.show("Request Successful", "success");
                }).catch(error => {
                    HWMessage.show(error && error.response && error.response.data && error.response.data.message || "Request could not be resolved", "error");
                    successStatus = false;
                })
                .finally(() => {
                    setRuleLoading(false);
                    if (successStatus === true) {
                        props.setDirectActionOpen(false);
                    }
                });
        }
    });

    const checkDisabled = () => {
        if (props.createMode === true) {
            if (currentRule && currentRule.action && currentRule.description && currentRule.description !== '' && currentRule.action !== '') {
                return false;
            }
            else {
                return true;
            }
        }
        else {
            if (currentRule && currentRule.action && currentRule.pushNotificationBody && currentRule.pushNotificationBody !== '' && currentRule.pushNotificationBody !== '') {
                return false;
            }
            else {
                return true;
            }

        }
    }

    const getValue = (value) => {
        switch (value) {
            case "SignUp": return "Sign Up"; break;
            case "BikeVerifiedFromJpj": return "Bike Verified From Jpj"; break;
            case "BikeVerifiedByOpsUser": return "Bike Verified By Ops User"; break;
            case "BikeRejectedByOpsUser": return "Bike Rejected By Ops User"; break;
            case "BikeApplicableForManual": return "Bike Applicable For Manual"; break;
            case "BonusPointsAwarded": return "Bonus Points Awarded"; break;
            case "VoucherAwarded": return "Voucher Awarded"; break;
            case "VoucherAwardedManually": return "Voucher Awarded Manually"; break;
            case "PointsAwardedForPurchaseByMerchant": return "Points Awarded For Purchase By Merchant"; break;
            case "PointsDeductionRequestByMerchant": return "Points Deduction Request By Merchant"; break;
            case "PointsRedeemedForPOSDiscount": return "Points Redeemed For POS Discount"; break;
            case "PointsAwardedByMerchant": return "Points Awarded By Merchant"; break;
            case "EventCheckIn": return "Event Check In"; break;
            case "PointsAwardedOnCheckIn": return "Points Awarded On CheckIn"; break;
            case "PointsRedeemedForWoocommerceOrder": return "Points Redeemed For Woocommerce Order"; break;
            case "PointsAwardedForGOSOrder": return "Points Awarded For GOS Order"; break;
            case "PointsAwardedManually": return "Points Awarded Manually"; break;
            case "PointsDeductedManually": return "Points Deducted Manually"; break;
            case "MembershipDowngraded": return "Membership Downgraded"; break;
            case "MembershipExpired": return "Membership Expired"; break;
            case "MembershipReactivated": return "Membership Reactivated"; break;
            case "RemovedAssignedUser": return "Removed Assigned User"; break;
            case "PointsExpired": return "Points Expired"; break;
            case "BikeAssigned": return "Bike Assigned"; break;
            case "VoucherExpired": return "Voucher Expired"; break;
            case "EServiceCouponExpired": return "EService Coupon Expired"; break;
            case "VoucherRedeemed": return "Voucher Redeemed"; break;
            case "EServiceCouponRedeemed": return "E Service Coupon Redeemed"; break;
            case "EmailUpdated": return "Email Updated"; break;
            case "MobileNumberUpdated": return "Mobile Number Updated"; break;
            case "PasswordUpdated": return "Password Updated"; break;
            case "ServiceRequestRaised": return "Service Request Raised"; break;
            case "UploadedMembershipCard": return "Uploaded Membership Card"; break;
            case "MembershipUpgraded": return "Membership Upgraded"; break;
            case "PointsRedeemedOnPurchaseOfDigitalVoucher": return "Points redeemed on purchase of Digital Voucher"; break;
            case "PointsAwardedByYamahaOnSignupViaReferral": return "Points Awarded By Yamaha On Signup via Referral"; break;
            case "PointsAwardedByYamahaForReferringFriend": return "Points Awarded By Yamaha For Referring a friend"; break;

        }
    }

    console.log("not", currentRule);

    const captureChanges = event => {
        const field = event.target.name;
        const value = event.target.value;
        console.log(field, value);
        switch (field) {

            case "action":
                if (value.trim() == "")
                    break;
                setCurrentRule(prev => ({ ...prev, description: defaultMessages[value]["message"] }));
                setPlaceHolders(defaultMessages[value]["placeHolders"]);

                // defaultMessages[value]["placeHolders"].map(i=>placeholders.push({ name: i.slice(1,i.length-1), placeholder: i }));
                // console.log(placeholders);


                break;

            case "description":
                if (props.createMode === true)
                    setCurrentRule(prev => ({ ...prev, description: value }));
                else
                    setCurrentRule(prev => ({ ...prev, pushNotificationBody: value }));

                break;

        }
        //setCurrentRule (prev => ({ ...prev, [field] : value })); 
    };
    const handleSave = () => {
        if (props.createMode === true) { createRule(currentRule); }
        else if (props.createMode === false) { updateRule(currentRule); }
    };
    const handleCancel = () => { props.setOpen(prev => false); };

    return (
        <>
            <BlockUi blocking={ruleLoading} message={'Please wait while we fetch.'} >

                <Card className={classes.card} >
                    {props.createMode === true && <CardHeader style={{ margin: 0, padding: 5 }}> <center> <h3> Notification Configuration </h3>   </center>   </CardHeader>
                    }
                    {props.createMode === false && <CardHeader style={{ margin: 0, padding: 5 }}>  <h3> Notification Configuration &nbsp; <a style={{ cursor: "pointer" }}><Edit style={{ color: "#138de8" }} onClick={e => setEdit(!edit)} /></a>  </h3>      </CardHeader>
                    }
                    <CardBody>
                        <GridContainer>



                            <GridItem xs={6} md={6} sm={6} >

                                <DropDown
                                    labelCategory="Select Notification Type"
                                    name="action"
                                    values={types}
                                    minWidth={300}
                                    targetValue={props.createMode === true ? currentRule && currentRule.action : getValue(currentRule && currentRule.action)}
                                    onChange={e => { setCurrentRule(prev => { prev.action = e.target.value; return { ...prev }; }); }}
                                    onBlur={captureChanges}
                                    helperText={fetchErrorMessage('action')}
                                    hasError={checkError('action')}
                                    disabled={props.createMode === false ? true : false}
                                    required
                                />
                            </GridItem>

                            {placeHolders.length > 0 && <GridItem xs={12} md={12} sm={12} >
                                <h4 style={{ fontWeight: 300 }}>Placeholders available</h4>
                                {/* { placeHolders.map((placeholder , idx) => <> <Draggable><Chip style = {{ color : 'floralwhite', backgroundColor : "#FF9017" , left: 30 ,fontWeight: 900}} label =  {placeholder}  /></Draggable>  </> )} */}
                                {placeHolders.map((placeholder, idx) => <> <Chip style={{ color: 'floralwhite', backgroundColor: "#FF9017", left: 30, fontWeight: 900 }} label={placeholder} /> </>)}

                            </GridItem>}


                            <GridItem xs={12} md={12} sm={12} >

                                <GridContainer >

                                    <GridItem xs={12} md={12} sm={12} >
                                        {currentRule && currentRule.description && <h4>Notification Body</h4>}
                                        {/* <center>

                                             <TextField 
                                               style = {{ marginTop : 20 }}
                                               multiline = { true }
                                               rows = { 10 }
                                               rowsMax = { 20 }
                                               value = {props.createMode === true ? currentRule && currentRule.description : currentRule && currentRule.pushNotificationBody}
                                               fullWidth
                                               variant = "outlined"
                                               label = {`Description` }
                                               placeholder = 'Specify the Notification. '
                                               name = { 'description' }
                                               InputLabelProps = {{ shrink : true }}
                                              // inputProps = {{ maxLength : 100 }}
                                              //  onBlur = { captureChanges }
                                               error = { checkError ('description') }
                                               onChange =  { captureChanges  }
                                              helperText = { fetchErrorMessage ('description') }
                                              disabled = { props.createMode === false ? !edit : false }

                                            />
                              </center> */}

                                        {<AutocompleteTextArea2 label="description" required={true} disabled={props.createMode === false ? !edit : false} value={props.createMode === true ? currentRule && currentRule.description : currentRule && currentRule.pushNotificationBody}


                                            name="description" onChange={e => (props.createMode === true ? setCurrentRule(prev => ({ ...prev, description: e.target.value })) : setCurrentRule(prev => ({ ...prev, pushNotificationBody: e.target.value })))} placeholders={props.createMode === true ? placeHolders : defaultMessages[getValue(props.ruleId.action)]["placeHolders"]} />}

                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} >
                                {<center>
                                    {settings.includes("Action") === true && props.createMode === false && edit === true && <Button disabled={checkDisabled()} onClick={e => props.createMode === true ? setCreateOpen(true) : setSaveOpen(true)} raised variant="contained" style={{ backgroundColor: '#138DE8', color: 'floralwhite', textTransform: 'none' }} > {props.createMode === true ? 'Create Notification' : 'Update Notification'} </Button>
                                    }
                                    {settings.includes("Action") === true && props.createMode === true && <Button disabled={checkDisabled()} onClick={e => props.createMode === true ? setCreateOpen(true) : setSaveOpen(true)} raised variant="contained" style={{ backgroundColor: '#138DE8', color: 'floralwhite', textTransform: 'none' }} > {props.createMode === true ? 'Create Notification' : 'Update Notification'} </Button>
                                    }
                                    <Button onClick={handleCancel} raised variant="contained" style={{ backgroundColor: '#FF9017', color: 'floralwhite', textTransform: 'none' }} > Cancel </Button>
                                    <ConfirmationDialog open={createOpen} setOpen={setCreateOpen} confirmingAction={handleSave} dialogMessage={'Are you sure you want to create this notification'} dialogTitle={'Confirm  notification creation'} />
                                    <ConfirmationDialog open={saveOpen} setOpen={setSaveOpen} confirmingAction={handleSave} dialogMessage={'Are you sure want to update the notification.'} dialogTitle={'Confirm notification Updation'} />
                                    <StatusDialog isLoading={false} open={open3} setOpen={setOpen3} sucessStatus={"success"} confirmingAction={e => props.setOpen(false)} message={"Notification successfully updated."} dialogTitle={"Status"} />
                                    <StatusDialog isLoading={false} open={open2} setOpen={setOpen2} sucessStatus={"success"} confirmingAction={e => props.setOpen(false)} message={"Notification successfully created."} dialogTitle={"Status"} />

                                </center>}
                            </GridItem>
                        </GridContainer>
                    </CardBody>
                </Card>
            </BlockUi>
        </>
    );

};