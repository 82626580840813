import { Card, Paper, Dialog, DialogTitle, DialogContent, makeStyles } from '@material-ui/core';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { HWInput } from 'HWLibrary/HWInput';
import React, { useEffect, useState } from 'react';
import cloudfrontConfig from 'HWLibrary/HWCloudfrontConfig';
import AutocompleteTextArea from "views/Utils/TextAreaAutoComplete";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";

export default function TransactionDetails (props) {

    const [ transaction, setTransaction ] = useState (props && props.transaction);
    const [ points, setPoints ] = useState (props && props.points);
    const [ transactionViewModel, setTransactionViewModel ] = useState (null);
    const [ creator, setCreator ] = useState (props.creator);
    const [ loading, setLoading ] = useState (false);
    const [source , setSource ] = useState(props.from);
    const [enlargedImage, setEnlargedImage] = useState (false);
    const [ imageChosen, setImageChosen ] = useState (-1);
    const [ currentVOC, setCurrentVOC ] = useState (props.memberDetails); 
    const [ imageName, setImageName ] = useState (null);
    const enlargeImage = (imageLink, name) => {setEnlargedImage (true); setImageChosen(imageLink); setImageName (name)};
    
    

    useEffect (() => { 
        if (transaction) {
            setLoading (true);
            setTransactionViewModel (createTransactionViewModel (transaction)); 
            setLoading (false);
        }
    }, [transaction, points]);    
    
    console.log("Transaction", transaction, transactionViewModel); 
    const createTransactionViewModel = (transaction) => {
        if (transaction) {
            return {
                id : transaction.id,
                transactionTime : new Date(transaction.transactionTime),
                pointsExpiryDate : new Date(points.expiryDate).toLocaleDateString(),
                conversionRate : points.conversionRate,
                description : transaction.description,
                pointsInvolved : pointAmount(transaction),
                transactionType : transactionNames[transaction.transactionType],
                awardedInCampaignId : transaction.awardedInCampaignId, 
                pointAction : transaction.pointAction, 
                membershipCardId : transaction.membershipCardId,
                appliedVoucherId : transaction.appliedVoucherId,
                location : transaction.location,
                purchaseAmount : transaction.purchaseAmount,
                spendCategories : transaction.spendCategories,
                amountSpent : transaction.amountSpent, 
                yamahaPointsRewarded : (transaction.transactionType !== 'OpsDeduct') && transaction.yamahaPointsRewarded,
                yamahaPointsDeducted : (transaction.transactionType === 'OpsDeduct') && transaction.yamahaPointsRewarded,
                merchantPointsRewarded : transaction.merchantPointsRewarded, 
                campaignCode : transaction.campaignCode, 
                pointsRewarded : source === "Points" ? transaction.pointsDeducted : transaction.pointsRewarded ,
                pointsDeducted : source === "Points" ? transaction.pointsRewarded : transaction.pointsDeducted,
                pointsSpent : transaction.pointsSpent,
                pointsPurchased : transaction.pointsPurchased,
                pointsReturned : transaction.pointsReturned,
                memberName: transaction.memberName,
                membershipNumber : transaction.membershipNumber,
                merchantId : transaction.merchantId,
                pointsRequestRefNumber : transaction.pointsRequestRefNumber,
                merchantRemarks : transaction.merchantRemarks,
                merchantRemarksAttachment : transaction.merchantRemarksAttachment

            }
        } else { return null; }  
    };
    const transactionNames = { "RegularSale" : "Regular Sale", "CampaignSale" : "Campaign Sale", 
        "PointReturnPending" : "Points Returned (Pending)", "PointPurchase" : "Points Purchased", 
        "PointReturnApproved" : "Points Returned (Approved)", "CheckIn" : "Check In", 
        "OpsAward" : "Awarded By Ops", "OpsDeduct" : "Deducted By Ops", "BonusPoints" : "Bonus Points", 
        "GenBluStore" : "Gen Blu Purchase", "WooCommerceSale" : "Woo Commerce Purchase", 
        "PointsExpired" : "Points Expired" , "PointPurchasePending" : "Point Purchase Pending", 
        "POSDiscount" : "POS Discount", "MerchantAward": "Merchant Award", "POSAward": "POS Award", 
        "DigitalVoucherRedemption" : "Digital Voucher Redemption", "ReferralAward": "Referral Reward"};
    const pointAmount = transaction => {
        switch (transaction.transactionType) {
            case "RegularSale" : 
            case "CheckIn" : 
            case "OpsAward" :
            case "GenBluStore" :
            case "POSAward":
            case "ReferralAward":
            case "MerchantAward":
            case "CampaignSale" : return transaction.pointsRewarded;
            case "POSDiscount":
            case "WooCommerceSale" : return transaction.pointsSpent;
            case "OpsDeduct" :
            case "PointsExpired" : return transaction.pointsDeducted;
            case "PointReturnPending" : 
            case "PointReturnRejected" : 
            case "PointReturnApproved" : return transaction.pointsReturned;
            case "PointPurchase" : return transaction.pointsPurchased;
            case "PointPurchasePending" : return transaction.pointsPurchased;
            case "DigitalVoucherRedemption": return transaction.yamahaPointsRewarded;
        }
    };

    const getTextColor = pointAction => {
        switch (pointAction) {
            case "plus" : return source === "Points" ?  "#F44A5C" :'#21781C';
            case "minus" : return source === "Points" ? '#21781C' : "#F44A5C";
        }
    };


    return (
        <>
        { !loading && transactionViewModel &&
            <>
                        { transactionViewModel.pointAction &&  
                        <GridContainer justify = "space-between" >
            { transactionViewModel.transactionType !== undefined && transactionViewModel.transactionType!=="Point Purchase Pending"  &&   <GridItem> <h3 style = {{ color :  getTextColor(transactionViewModel.pointAction) }} > <b>  Points { source === "Points" ?  transactionViewModel.pointAction === "plus" ? "Deducted" : "Accrued" : transactionViewModel.pointAction === "plus" ? "Accrued" : "Deducted" } </b> </h3>  </GridItem> }     
            { transactionViewModel.transactionType !== undefined  && transactionViewModel.transactionType==="Point Purchase Pending"  &&   <GridItem> <h3 style = {{ color :  "red" }} > <b>  Points Purchase Failed </b> </h3>  </GridItem> }               
          
             { transactionViewModel.transactionType !== undefined  && transactionViewModel.transactionType!=="Point Purchase Pending" && transactionViewModel.pointsInvolved && <GridItem> <h3 style = {{ color :  getTextColor(transactionViewModel.pointAction) }} > <b> { source === "Points" ? transactionViewModel.pointAction === "plus" ? '-' : '+' : transactionViewModel.pointAction === "plus" ? '+' : '-'} { transactionViewModel.pointsInvolved } </b> </h3> </GridItem>}
             { transactionViewModel.transactionType !== undefined  && transactionViewModel.transactionType==="Point Purchase Pending" && transactionViewModel.pointsInvolved && <GridItem> <h3 style = {{ color :  "red" }} > <b> { source === "Points" ? "" : ""} { transactionViewModel.pointsInvolved } </b> </h3> </GridItem>}

                        </GridContainer>
                        }
                        {transactionViewModel.transactionType !== undefined && transactionViewModel.transactionType!=="Point Purchase Pending"  &&  transactionViewModel.id && <HWInput label = "Transaction ID" value = { transactionViewModel.id }  disabled = { true } />}
                        { transactionViewModel.transactionType && <HWInput label = "Transaction Type" value = { transactionViewModel.transactionType === "Point Purchase Pending"  ? "Points Purchase Failed" :transactionViewModel.transactionType }  disabled = { true } />}
                        { transactionViewModel.transactionTime && <HWInput label = "Transaction Date" value = { new Date(transactionViewModel.transactionTime).toLocaleDateString() } disabled = { true } />}
                        {transactionViewModel.transactionType !== undefined && transactionViewModel.transactionType!=="Point Purchase Pending"  &&  transactionViewModel.description && <HWInput label = "Summary" value = { transactionViewModel.description } disabled = { true } />}
                        {transactionViewModel.transactionType !== undefined && transactionViewModel.transactionType==="Point Purchase Pending"  &&  transactionViewModel.description && <HWInput label = "Summary" value = { source === "Points" ?  "Subscription Transaction Failed" :"Points Purchase transaction Failed"} disabled = { true } />}
                        
                        { (creator === 'member') && transactionViewModel.pointsExpiryDate && <HWInput label = "Points Validity" value = { transactionViewModel.pointsExpiryDate } disabled = { true } />}
                        { transactionViewModel.membershipCardId && <HWInput label = "Card Used" value = { transactionViewModel.membershipCardId }  disabled = { true } /> }
                        { transactionViewModel.purchaseAmount && transactionViewModel.purchaseAmount > 0  ? <HWInput label = "Purchase Amount" value = { transactionViewModel.purchaseAmount } disabled = { true } /> : <></>} 
                        { transactionViewModel.appliedVoucherId && <HWInput label = "Voucher Applied" value = { transactionViewModel.appliedVoucherId } disabled = { true } />}
                        { transactionViewModel.amountSpent && <HWInput label = "Amount Spent" value = { transactionViewModel.amountSpent } disabled = { true } /> }

                        { transactionViewModel.pointsRewarded && transactionViewModel.pointsRewarded !== null && <HWInput label = "Total Points Rewarded" value = { transactionViewModel.pointsRewarded } disabled = { true } />}
                        { transactionViewModel.pointsReturned && transactionViewModel.pointsReturned !== null && <HWInput label = "Total Points Returned" value = { transactionViewModel.pointsReturned } disabled = { true } />}
                        { transactionViewModel.pointsSpent && transactionViewModel.pointsSpent !== null 
                        && <HWInput label = { (transactionViewModel.pointAction === "plus") ? "Total Points Accrued" : "Total Points Spent"} value = { transactionViewModel.pointsSpent } disabled = { true } />}
                        { transactionViewModel.pointsDeducted && transactionViewModel.pointsDeducted !== null && <HWInput label = {"Total Points Deducted"} value = { transactionViewModel.pointsDeducted } disabled = { true } />}
                        { transactionViewModel.pointsPurchased && transactionViewModel.pointsPurchased !== null && <HWInput label = "Total Points Purchased" value = { transactionViewModel.pointsPurchased } disabled = { true } />}

                        { transactionViewModel.merchantPointsRewarded && transactionViewModel.merchantPointsRewarded !== 0  && <HWInput label = "Points Rewarded By Merchant"  value = { transactionViewModel.merchantPointsRewarded } disabled = { true } />  }
                        { transactionViewModel.yamahaPointsRewarded !== undefined && transactionViewModel.yamahaPointsRewarded !== null && transactionViewModel.yamahaPointsRewarded > 0 && <HWInput label = {source === "Points" ? "Points Deducted by Yamaha" : "Points Rewarded by Yamaha" }value = { transactionViewModel.yamahaPointsRewarded }  disabled = { true } />}
                        { transactionViewModel.yamahaPointsRewarded !== undefined && transactionViewModel.yamahaPointsDeducted !== null && transactionViewModel.yamahaPointsDeducted > 0 && <HWInput label = {source === "Points" ? "Points Rewarded by Yamaha" : "Points Deducted by Yamaha"} value = { transactionViewModel.yamahaPointsDeducted }  disabled = { true } />}
                        { transactionViewModel.pointsRequestRefNumber !== undefined && transactionViewModel.pointsRequestRefNumber !== null && <HWInput label = "Reference Number" value = { transactionViewModel.pointsRequestRefNumber }  disabled = { true } />}

                        { source === "Points" && transactionViewModel.memberName !== undefined && transactionViewModel.memberName !== null  && <HWInput label = "Member" value = { transactionViewModel.memberName }  disabled = { true } />}
                        { source === "Points" &&transactionViewModel.membershipNumber !== undefined && transactionViewModel.membershipNumber !== null  && <HWInput label = "Membership Number" value = { transactionViewModel.membershipNumber }  disabled = { true } />}
                        { source === "Points" &&transactionViewModel.merchantId !== undefined && transactionViewModel.merchantId !== null  && <HWInput label = "MerchantId" value = { transactionViewModel.merchantId }  disabled = { true } />}

                        { false && transactionViewModel.awardedInCampaignId && <HWInput label = "Campaign" value = { transactionViewModel.awardedInCampaignId } disabled = { true } />}
                        { transactionViewModel.campaignCode && <HWInput label = "Campaign Code" value = { transactionViewModel.campaignCode } disabled = { true } />}
                        {transactionViewModel.merchantRemarks  && 
        <AutocompleteTextArea formControlProps={{ fullWidth: true}} labelText = "Merchant Remarks" value = { transactionViewModel.merchantRemarks } disabled = { true } /> }

                        { transactionViewModel.merchantRemarksAttachment &&
                            <GridItem xs = {6} style = {{ marginTop : '30px',marginLeft : '-15px', width : '150px', padding:'0'}} >
                            <Paper elevation = {5} style = {{ width : 'fit-content' }} >
                                <img 
                                    name = "hello"
                                    src = { `${cloudfrontConfig.cloudfrontBaseUrl}/merchantRemarksAttachment-images/${transactionViewModel.merchantRemarksAttachment}`} 
                                    style = {{ height : '150px', width : '150px' }} 
                                    onClick = { () => {enlargeImage (`${cloudfrontConfig.cloudfrontBaseUrl}/merchantRemarksAttachment-images/${transactionViewModel.merchantRemarksAttachment}`, transactionViewModel.merchantRemarksAttachment) } } 
                                />
                            </Paper>
                        </GridItem>
                        }
                        {enlargedImage && <Lightbox image={imageChosen} onClose = { () => { setEnlargedImage(false) } }/> }
            </>
    }
    </>
    );
    
};

const useStyles = makeStyles (theme => ({
    
    cardFooter : { justifyContent : 'center' },
    cardHeader : { height : '50px' },   
    formControl: { margin: theme.spacing(1), minWidth: 120 }, 

    root: { '& .MuiButton-label' : { color : '#138DE8' } },
    newPosOfDialog: {
        position: "absolute",
        top: "50%",
        left: "70%",
        transform: "translate(-50%, -50%)",
      }
}));